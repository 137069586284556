<template>
  <section>
    <sub-head idName="act-dis" content="活动展示" />
    <div class="display-line-box">
      <process-line v-for="index in content.length" :key="index" :index="index">
        {{ content[index - 1] }}
      </process-line>
    </div>
  </section>
</template>
<script>
import ProcessLine from '@/components/ProcessLine.vue'
import SubHead from '@/components/SubHead.vue'
import actDisData from '@/assets/json/actDis.json'
export default {
  components: { SubHead, ProcessLine },
  name: 'ActDis',
  data() {
    return {
      content: actDisData
    }
  }
}
</script>
