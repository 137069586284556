<template>
  <div id="section">
    <div class="bottom"></div>
    <Home />
    <Index />
    <Back />
    <Foot />
  </div>
</template>

<script>
import Foot from './components/Foot.vue'
import Home from './view/Home'
import Index from './view/Index'
import Back from './view/Index/Back.vue'

export default {
  name: 'App',
  components: {
    Home,
    Index,
    Foot,
    Back
  }
}
</script>

<style scoped>
.bottom {
  position: fixed;
  z-index: -1;
  top: 0;
  /*left: 0;*/
  height: 100%;
  width: 750px;
  background: linear-gradient(
    145deg,
    rgb(247, 244, 213),
    rgb(47, 154, 232),
    rgb(129, 205, 229),
    rgb(47, 154, 232)
  );
  background-size: 400% 100%;
  background-position: 100% 50%;
  /*background-attachment: fixed;*/
}

#section {
  width: 750px;
  height: 100%;
  margin: auto;
  animation: wave-animation 3s ease infinite alternate;
  box-shadow: 2px 5px 40px 10px rgba(0, 0, 0, 0.281);
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 768px) {
  #section {
    width: 100%;
  }
}
</style>
