<template>
  <section :class="className">
    <div>
      <div class="box-img">
        <img :src="avatarSrc" alt="照片" class="avatar" />
      </div>
      <div>{{ teacherName }}</div>
    </div>
    <div class="box-content">
      <slot></slot>
    </div>
  </section>
</template>
<script>
export default {
  name: 'TeaIntro',
  props: {
    teacherName: String,
    avatar: String,
    type: String,
    imgName: String
  },
  data() {
    return {
      avatarSrc: require(`@/assets/images/${this.imgName}.jpg`),
      className: 'box-tea-intro-' + this.type
    }
  }
}
</script>
<style scoped>
.box-img {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  border: 0.13rem solid #ffffff;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  overflow: hidden;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

[class^='box-tea-intro-'] {
  display: flex;
  flex-direction: row;
}

.box-tea-intro-even {
  flex-direction: row-reverse;
}

[class^='box-tea-intro-'] > div:first-of-type {
  margin: 1rem 1.5rem 0 1rem;
  justify-content: center;
  text-align: center;
}

.box-content p {
  text-indent: 2em;
  line-height: 1.8rem;
  margin: 0.5rem;
}
</style>
