<template>
  <section id="mark-survey" :class="className">
    <Title ch="社团总介绍" en="general introduction" />
    <section class="box" ref="survey">
      <DisplayCard
        v-for="({ title, content }, index) in data"
        :key="index"
        :index="index"
        :title="title"
        :type="2"
      >
        <p v-for="(item, idx) in content" :key="idx">{{ item }}</p>
      </DisplayCard>
    </section>
  </section>
</template>
<script>
import Title from '@/components/Title'
import DisplayCard from '@/components/DisplayCard.vue'
import surveyData from '@/assets/json/survey.json'

export default {
  name: 'Survey',
  data() {
    return {
      data: surveyData
    }
  },
  props: {
    className: String
  },
  components: {
    Title,
    DisplayCard
  }
}
</script>
