<template>
  <box-head :type="type" :headContent="headContent">
    <div v-for="person in data" :key="person.id" class="box-stu">
      <section :class="className">
        <div class="box-stu-name">{{ person.name }}</div>
        <div>
          学号{{ person.id }}，{{ person.work }}，来自{{ person.major }}
        </div>
      </section>
    </div>
  </box-head>
</template>
<style scoped>
.box-stu {
  margin: 1rem 0;
  padding: 0 1rem;
  position: relative;
}
.box-stu > section::before {
  content: '';
  position: absolute;
  height: 0.35rem;
  width: 0.35rem;
  background-color: #ffffff;
  border: 0.15rem solid #c5d5eb9f;
  border-radius: 50%;
  top: 0.48rem;
  left: -0.4rem;
}
.box-stu-name {
  font-size: 1.15rem;
  margin-bottom: 0.4rem;
}
</style>
<script>
import BoxHead from '../../../../components/BoxHead.vue'
export default {
  components: { BoxHead },
  name: 'StuIntro',
  props: {
    year: Number,
    type: {
      type: String,
      default() {
        return this.year % 2 === 1 ? 'odd' : 'even'
      }
    },
    data: Array
  },
  data() {
    return {
      headContent: '第 ' + this.year + ' 届',
      className: 'box-student-intro-' + this.type
    }
  }
}
</script>
