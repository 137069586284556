<template>
  <section id="join-us" :class="className">
    <Title ch="加入我们" en="how to join us" />
    <section>
      <div class="img-func-intro" id="zhaoxin">
        <img src="@/assets/images/zhaoxin.jpg" alt="迎新群" />
      </div>
    </section>
  </section>
</template>
<script>
import Title from '../../components/Title.vue'
export default {
  components: { Title },
  props: {
    className: String
  },
  name: 'Join'
}
</script>
