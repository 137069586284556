<template>
  <section id="his-dev" :class="className">
    <Title ch="历史沿革" en="historical development" />
    <main>
      <dev-his />
      <mana-evo />
    </main>
  </section>
</template>
<script>
import Title from '../../components/Title.vue'
import DevHis from './History/DevHis.vue'
import ManaEvo from './History/ManaEvo.vue'
export default {
  components: { Title, DevHis, ManaEvo },
  props: {
    className: String
  },
  name: 'History'
}
</script>
