<template>
  <Card :styleName="getCardClassName()">
    <span class="box-subhead" v-if="!!header && index === 0">{{ header }}</span>
    <div :class="`box-survey-head-${idx}`" v-if="!!title">
      <h4 :id="`box-survey-${idx}`">{{ title }}</h4>
    </div>
    <div class="box-survey-content">
      <slot></slot>
    </div>
  </Card>
</template>
<script>
import Card from './Card.vue'
export default {
  props: {
    header: {
      type: String,
      required: false
    },
    index: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      idx: (this.index % 3) + 1
    }
  },
  methods: {
    getCardClassName() {
      const isEven = this.index % 2 === 1
      let idx
      if (isEven) {
        idx = this.type
      } else {
        idx = this.index % 4 === 0 ? 1 : 2
      }
      return `${isEven ? 'even' : 'odd'}-${idx}`
    }
  },
  components: { Card }
}
</script>

<style scoped>
.box-subhead {
  /* display: inline-block; */
  width: 6rem;
  text-align: end;
  background-color: #ffffff;
  color: #5fbcff;
  margin: 1rem 0rem 0.5rem -0.5rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
  font-size: 1.1rem;
}
</style>
