<template>
  <header>
    <section>
      <div>
        <h4>杭电助手</h4>
        <h4>HDU HELP</h4>
      </div>
    </section>
    <div>
      <img src="@/assets/images/hduhelp.png" alt="杭助图标" />
    </div>
  </header>
</template>
<script>
import { scrollToTop } from '@/utils/common'

export default {
  name: 'Head',
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.body.offsetHeight - 10 &&
        document.getElementById('home').getAttribute('class') !== 'home-hide'
      ) {
        document.getElementById('home').setAttribute('class', 'home-hide')
        document.getElementById('index').setAttribute('class', 'index-show')
        setTimeout(() => {
          document
            .getElementById('mark-survey')
            .setAttribute('class', 'section-show')
        }, 100)
        scrollToTop()
      }
    }
  }
}
</script>

<style scoped>
header {
  display: flex;
  position: relative;
  margin: auto;
  height: 35vh;
  width: 100%;
  color: white;
  background: linear-gradient(
    145deg,
    rgb(128, 208, 241),
    rgb(47, 154, 232),
    rgb(129, 205, 229),
    rgb(47, 154, 232)
  );
  border-radius: 0px 0px 2rem 2rem;
  align-items: center;
  overflow: hidden;
  animation: wave-animation 2s ease infinite alternate;
  background-size: 400% 100%;
  background-position: 100% 50%;
}

header > section > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
  letter-spacing: 0.3rem;
  margin-top: 3rem;
}

header > section > div:first-child > h4:nth-of-type(2) {
  font-size: 1rem;
  letter-spacing: 0.5rem;
  font-weight: 300;
}

@keyframes header-show {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

header > section > div:nth-of-type(2) {
  color: rgb(128, 208, 241);
  margin: 1rem;
  padding: 0.4rem 1.6rem;
  border-radius: 1rem;
  letter-spacing: 0.1rem;
  background-color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
}

header > section {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  animation: header-show 2s;
}

header::before {
  content: '杭州电子科技大学社团';
  font-size: 0.8rem;
  opacity: 80%;
  position: absolute;
  left: 50%;
  bottom: 1.2rem;
  transform: translateX(-50%);
}

header > div {
  flex: 1;
}

header > div > img {
  position: absolute;
  height: 250px;
  top: -2rem;
  right: -2rem;
}

@media screen and (max-width: 768px) {
  header > section > div:nth-of-type(2) {
    font-size: 0.9rem;
  }

  header > section > div:first-child {
    font-size: 1.8rem;
    margin-top: 3.5rem;
  }

  header > section > div:first-child > h4:nth-of-type(2) {
    font-size: 0.8rem;
  }

  header::before {
    bottom: 0.7rem;
  }

  header > div > img {
    height: 90%;
    top: -5%;
    right: -5%;
  }
}
</style>
