<template>
  <section>
    <div :class="className">
      <h4>{{ headContent }}</h4>
    </div>
    <div class="box-survey-content">
      <slot></slot>
    </div>
  </section>
</template>
<script>
export default {
  name: 'BoxHead',
  props: {
    type: String,
    headContent: String
  },
  data() {
    return {
      className: 'box-intro-head-' + this.type
    }
  }
}
</script>
