<template>
  <div class="card">
    <div :class="className">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    styleName: String
  },
  data() {
    return {
      className: 'box-card-' + this.styleName
    }
  }
}
</script>
<style scoped>
[class^='box-card-odd-']::after {
  content: 'hdu help';
  position: absolute;
  text-transform: uppercase;
  color: #6fd9ff;
  right: 0;
  top: 48%;
  letter-spacing: 0.4rem;
  transform: rotate(90deg) translateY(-280%);
}

[class^='box-card-odd-']::before {
  content: '';
  position: absolute;
  background-color: #b6e2ff;
  width: 100%;
  height: 100%;
  top: 1rem;
  left: 1rem;
  z-index: -1;
}

.box-card-odd-1 {
  border-top-right-radius: 1rem;
}

.box-card-odd-2 {
  border-bottom-right-radius: 1rem;
}

.box-card-odd-1::before {
  border-top-right-radius: 1rem;
}

.box-card-odd-2::before {
  border-bottom-right-radius: 1rem;
}

.box > div:nth-of-type(even) {
  margin-left: auto;
  margin-top: 1rem;
}

.box-card-even-1 {
  background-color: #7acaff;
  border-top: 1.2rem solid #5fbcff;
  border-left: 1.2rem solid #5fbcff;
  background-image: url(@/assets/images/hduhelp.png);
  background-size: 7rem;
  background-repeat: no-repeat;
  background-position: center right;
}

.box-card-even-1::after {
  content: 'hdu help';
  position: absolute;
  text-transform: uppercase;
  color: #6fd9ff;
  top: -1.2rem;
  left: 2rem;
  letter-spacing: 0.4rem;
}

.box-card-even-2 {
  background-image: url(@/assets/images/hduhelp.png);
  background-size: 4rem;
  background-repeat: no-repeat;
  background-position: bottom right;
  border-bottom-left-radius: 1rem;
}

.box-card-even-2::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 1rem;
  background-color: #b6e2ff;
  top: 1rem;
  right: 1rem;
  z-index: -1;
}

.box-card-even-2::after {
  content: 'hdu help';
  position: absolute;
  text-transform: uppercase;
  color: #6fd9ff;
  left: 0;
  top: 48%;
  letter-spacing: 0.4rem;
  transform: rotate(90deg) translateY(280%);
}
</style>
