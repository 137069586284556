<template>
  <div id="home">
    <Head />
    <main>
      <sub-head idName="home-head" content="数据流量" />
      <section class="drop">
        <Drop
          id-name="home-main-1"
          v-for="{ content, title } in data"
          :key="content"
          :title="title"
          :content="content"
        />
      </section>
      <span class="iconGroup">
        <a href="https://github.com/hduhelp" >
          <img src="@/assets/images/github.svg" height="30px" alt="github" />
        </a>
        <div class="wechat-icon-container">
          <img src="@/assets/images/wechat.svg" height="30px" alt="微信" class="wechat-icon" />
          <div class="qrcode">
            <img src="@/assets/images/qrcode.jpg" alt="二维码" />
          </div>
        </div>
      </span>
    </main>
  </div>
</template>
<script>
import Drop from '@/components/Drop.vue'
import SubHead from '@/components/SubHead.vue'
import Head from './Home/Head'
import homeData from '@/assets/json/home.json'

export default {
  name: 'Home',
  data() {
    return {
      data: homeData
    }
  },
  components: {
    Head,
    Drop,
    SubHead
  }
}
</script>
<style scoped>
.iconGroup {
  display: flex;
  width: 100px;
  justify-content: space-around;
  padding-top: 5rem;
  margin: 1rem auto;
}

.wechat-icon-container {
  position: relative;
  display: inline-block;
}

.qrcode {
  position: absolute;
  top: -230px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.wechat-icon-container:hover .qrcode {
  visibility: visible;
  opacity: 1;
}

</style>
