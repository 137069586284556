<template>
  <div>
    <section class="drop-decor">
      <span>{{ title }}</span>
      <img src="@/assets/images/drop.jpg" alt="水滴图案" />
    </section>
    <p v-html="content"></p>
  </div>
</template>

<script>
export default {
  name: 'Drop',
  props: {
    title: String,
    content: String
  }
}
</script>
<style scoped>
#home .drop {
  padding-bottom: 4rem;
  border-radius: 0 0 2rem 2rem;
}

.drop {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.drop > div {
  display: flex;
  align-items: center;
  margin: 1rem 3rem;
  font-size: 0.95rem;
}

.drop >>> strong {
  color: #e5cf2b;
}

.drop img {
  width: 1.5rem;
  display: inline-block;
}

.drop p {
  margin: 0 1.5rem;
}

.drop > div:nth-of-type(even) {
  flex-direction: row-reverse;
}

.drop > div {
  position: relative;
  animation-name: bottom-to-top;
  animation-delay: 0.5s;
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.drop > div:nth-of-type(odd):before {
  content: '';
  position: absolute;
  top: -1.3rem;
  left: 0;
  display: block;
  font-size: 0.8rem;
  color: #13a6dc;
}

.drop > div:nth-of-type(even)::before {
  content: '';
  position: absolute;
  top: -1.3rem;
  right: -0.5rem;
  display: block;
  font-size: 0.8rem;
  color: #13a6dc;
}
.drop-decor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
}
.drop-decor > span {
  position: absolute;
  margin-top: -1.5em;
  color: #e5cf2b;
}
@media screen and (max-width: 768px) {
  .drop > div p {
    font-size: 0.8rem;
  }
}
</style>
