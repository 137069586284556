<template>
  <div>
    <div class="time-line-year">{{ time }}</div>
    <div :class="className">{{ year }}</div>
    <div class="time-line-detail">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TimeLine',
  data() {
    return {
      year: '',
      className: 'time-line-drop-' + this.type
    }
  },
  props: {
    time: String,
    type: String
  },
  mounted() {
    if (this.type === 'large') {
      this.year = this.time.split('/')[0] ?? this.time
    }
  }
}
</script>
