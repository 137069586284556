<template>
  <footer style="display: block; text-align: center">
    <div>©杭电助手 All Rights Reserved.</div>
    <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener" style="color: rgb(255, 255, 255, 0.69);">浙ICP备13033767号</a>
  </footer>
</template>
<script>
export default {
  name: 'Foot'
}
</script>
<style scoped>
footer {
  padding-bottom: 100px;
}
</style>
