<template>
  <div>
    <div class="display-line">
      <div>
        <div class="display-line-circle">
          {{ index }}
        </div>
      </div>
      <div class="display-line-intro">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProcessLine',
  props: {
    index: Number
  }
}
</script>
