<template>
  <div id="index" :class="indexClassName">
    <Head />
    <main>
      <Survey :className="survey" ref="survey" />
      <History :className="history" ref="history" />
      <Func :className="func" ref="func" />
      <Achieve :className="achieve" ref="achieve" />
      <Join :className="join" ref="join" />
    </main>
  </div>
</template>

<script>
import Head from './Index/Head'
import Survey from './Index/Survey'
import Achieve from './Index/Achieve'
import History from './Index/History'
import Func from './Index/Function'
import Join from './Index/Join'

export default {
  name: 'Index',
  data() {
    return {
      indexClassName: 'hide',
      survey: 'section-hide',
      history: 'section-hide',
      func: 'section-hide',
      achieve: 'section-hide',
      join: 'section-hide'
    }
  },
  components: {
    Head,
    Survey,
    Achieve,
    History,
    Func,
    Join
  },
  methods: {
    handleScroll() {
      let clientHeight = document.documentElement.clientHeight
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const historyDom = this.$refs.history.$el
      const funcDom = this.$refs.func.$el
      const achDom = this.$refs.achieve.$el
      const joinDom = this.$refs.join.$el
      if (document.getElementById('index').getAttribute('class') !== 'hide') {
        if (
          historyDom.getAttribute('class') === 'section-hide' &&
          scrollTop > historyDom.offsetTop - clientHeight
        ) {
          this.history = 'section-show'
        }
        if (
          funcDom.getAttribute('class') === 'section-hide' &&
          scrollTop > funcDom.offsetTop - clientHeight
        ) {
          this.func = 'section-show'
        }
        if (
          achDom.getAttribute('class') === 'section-hide' &&
          scrollTop > achDom.offsetTop - clientHeight
        ) {
          this.achieve = 'section-show'
        }
        if (
          joinDom.getAttribute('class') === 'section-hide' &&
          scrollTop > joinDom.offsetTop - clientHeight
        ) {
          this.join = 'section-show'
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
#index {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
</style>
