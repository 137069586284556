<template>
  <header>
    <div @click="hide" id="return">
      <svg
        t="1628947301310"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3364"
        width="32"
        height="32"
      >
        <path
          d="M270.3872 476.16a41.472 41.472 0 0 1 10.7008-9.0112l400.384-400.7424A49.8176 49.8176 0 0 1 717.1584 51.2c12.8 0 25.6 5.0688 35.7888 14.6944 19.8144 19.456 20.0192 51.2 0.512 70.9632l-375.1936 375.6032 374.784 373.8112a50.9952 50.9952 0 0 1 0.512 71.5264c-19.2512 19.9168-51.2 19.9168-71.0144 0.512L276.992 553.7792a49.9712 49.9712 0 0 1-20.8384-43.264v-1.536A50.688 50.688 0 0 1 270.336 476.16z"
          p-id="3365"
          fill="#ffffff"
        ></path>
      </svg>
    </div>
    <div>杭电助手</div>
    <nav id="nav-list-box">
      <svg
        id="nav-list-btn"
        @click="navShow"
        t="1628947460593"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4257"
        width="32"
        height="32"
      >
        <path
          d="M90.258176 848.52215466L933.08326402 848.52215466c46.36262401 0 84.29568 37.933056 84.29567998 84.29568s-37.933056 84.29568-84.29567999 84.29568001l-842.69337602 0c-46.36262401 0-84.29568-37.933056-84.29568-84.29568001-0.13171201-46.36262402 37.801344-84.29568 84.16396801-84.29568zM90.258176 435.86845867L928.078208 435.86845867c46.36262401 0 84.29568 37.933056 84.29568 84.29567999s-37.933056 84.29568-84.29568 84.29568l-837.820032 0c-46.36262401 0-84.29568-37.933056-84.29568-84.29568s37.933056-84.29568 84.29568-84.29567999zM90.258176 15.70717865L933.08326401 15.70717864c46.36262401 0 84.29568 37.933056 84.29567999 84.29568001s-37.933056 84.29568-84.29567999 84.29568003l-842.69337601 0c-46.36262401 0-84.29568-37.933056-84.29568001-84.29568003-0.13171201-46.36262402 37.801344-84.29568 84.16396801-84.29568z"
          fill="#ffffff"
          p-id="4258"
        ></path>
      </svg>
      <ul id="nav-list" :class="navClass" @click="navShow">
        <li v-for="{ id, desc } in navData" :key="id">
          <a :href="`#${id}`">{{ desc }}</a>
        </li>
      </ul>
      <div
        id="nav-mask"
        :style="{ display: navClass === 'list-show' ? 'block' : 'none' }"
        @click="navHide"
      ></div>
    </nav>
  </header>
</template>
<script>
let hideTimer
export default {
  name: 'Head',
  data() {
    return {
      navClass: 'nav-hide',
      navIsHide: this.navClass !== 'list-show',
      navData: [
        {
          id: 'mark-survey',
          desc: '社团总介绍'
        },
        {
          id: 'his-dev',
          desc: '历史沿革'
        },
        {
          id: 'func-intro',
          desc: '功能介绍'
        },
        {
          id: 'outs-ach',
          desc: '优秀成果'
        },
        {
          id: 'join-us',
          desc: '加入我们'
        }
      ]
    }
  },
  methods: {
    hide() {
      document.getElementById('index').setAttribute('class', 'index-hide')
      document.getElementById('home').setAttribute('class', 'home-show')
    },
    navShow() {
      const isHide = this.navClass !== 'list-show'
      clearTimeout(hideTimer) // 防闪
      if (isHide) {
        this.navClass = 'list-show'
        return
      }
    },
    navHide() {
      const isHide = this.navClass !== 'list-show'
      if (!isHide) {
        this.navClass = 'list-hide'
        hideTimer = setTimeout(() => {
          this.navClass = 'list-hide nav-hide'
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
#nav-list-box {
  cursor: pointer;
}

#nav-list {
  background-color: #ffffff;
  position: absolute;
  width: 7rem;
  top: 5rem;
  right: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 1rem #00000040;
  z-index: 10;
}

#nav-list > li {
  list-style: none;
  text-align: center;
  font-size: 0.9rem;
  border-bottom: 1px solid rgb(223, 220, 220);
}
#nav-list > li:last-of-type {
  border-bottom: 0;
}

#nav-list > li > a {
  display: inline-block;
  padding: 0.5rem;
  transition: 0.2s;
}

#nav-list > li > a:hover {
  color: #5fbcff;
}

.list-show {
  animation: right-to-left 1s forwards;
}

.list-hide {
  animation: left-to-right 0.5s forwards;
}

.nav-hide {
  display: none;
}
#nav-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
}

header {
  top: 0px;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 1.1rem;
  color: #ffffff;
}
</style>
