<template>
  <section id="outs-ach" :class="className">
    <Title ch="优秀成果" en="outstanding achievement" />
    <act-dis />
  </section>
</template>
<script>
import Title from '../../components/Title.vue'
import ActDis from './Achieve/ActDis.vue'
export default {
  components: { Title, ActDis },
  name: 'Achieve',
  props: {
    className: String
  }
}
</script>
