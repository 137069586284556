import { render, staticRenderFns } from "./Head.vue?vue&type=template&id=cdc90264&scoped=true&"
import script from "./Head.vue?vue&type=script&lang=js&"
export * from "./Head.vue?vue&type=script&lang=js&"
import style0 from "./Head.vue?vue&type=style&index=0&id=cdc90264&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdc90264",
  null
  
)

export default component.exports