<template>
  <header class="head">
    <div :id="idName">{{ content }}</div>
  </header>
</template>
<script>
export default {
  name: 'SubHead',
  props: {
    idName: String,
    content: String
  }
}
</script>
<style scoped>
#home-head::after {
  content: 'data flow';
}

#dev-his::after {
  content: 'DEVELOPMENT HISTORY';
}

#mana-evo::after {
  content: 'management evolution';
}
.head > div::after {
  font-size: 0.7em;
}

header {
  animation: header-show 1s 0.4s backwards;
}

@keyframes header-show {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
