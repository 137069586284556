<template>
  <section id="back-box" :class="className">
    <div id="back-to-top" @click="back">
      <svg-icon :width="20" :height="20">
        <icon-back />
      </svg-icon>
    </div>
    <section class="back-shadow"></section>
  </section>
</template>
<script>
import SvgIcon from '@/components/SvgIcon.vue'
import IconBack from '@/components/icons/IconBack.vue'
import { scrollToTop } from '@/utils/common'

export default {
  name: 'Back',
  data() {
    return {
      className: 'hide'
    }
  },
  components: {
    SvgIcon,
    IconBack
  },
  methods: {
    back() {
      // const index = document.getElementById('index')
      // if (index.getAttribute('class') === 'index-show') {
      //   index.scrollIntoView()
      //   return
      // }
      // document.getElementById('home').scrollIntoView()
      scrollToTop()
    },
    handleScroll: function () {
      this.className = 'back-show'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>
#back-box {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#back-to-top {
  font-size: 0.8rem;
  color: #ffffff;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-image: radial-gradient(at center, #61bcfd, #23a1fb); */
  background-color: #49aae7;
  border: 1px solid #fff;
  border-radius: 50%;
  animation-name: ball;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes ball {
  to {
    transform: translateY(-1rem);
  }
}
#back-to-top > a {
  color: #ffffff;
}
.back-shadow {
  width: 5rem;
  height: 0.8rem;
  background: rgba(0, 0, 0, 0.2);
  transform: translateY(0.5rem);
  border-radius: 50%;
  filter: blur(0.5rem);
  z-index: -1;
  animation-name: shadow;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes shadow {
  to {
    width: 5rem;
    height: 0.4rem;
    background: rgba(0, 0, 0, 0.2);
    filter: blur(1rem);
  }
}
.hide {
  display: none !important;
}
.back-show {
  animation: backShow 1s both;
}
@keyframes backShow {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
