<template>
  <header>
    <h3>
      {{ ch }}
    </h3>
    <div>
      <h4>{{ en }}</h4>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Title',
  props: {
    ch: String,
    en: String
  }
}
</script>
