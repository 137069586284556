<template>
  <section id="func-intro" :class="className">
    <Title ch="功能介绍" en="function introduction" />
    <div class="img-func-intro">
      <img
        src="@/assets/images/func-intro-1x.png"
        srcset="@/assets/images/func-intro-1x.png"
        alt="功能介绍"
      />
    </div>
    <section class="box">
      <DisplayCard v-for="(item, idx) in data" :key="idx" :index="idx">
        <box-head
          :type="idx % 2 === 0 ? 'odd' : 'even'"
          v-for="{ title, content } in item"
          :key="title"
          :headContent="title"
        >
          <p>{{ content }}</p>
        </box-head>
      </DisplayCard>
    </section>
  </section>
</template>
<script>
import BoxHead from '@/components/BoxHead.vue'
import DisplayCard from '@/components/DisplayCard.vue'
import Title from '@/components/Title.vue'
import funcData from '@/assets/json/function.json'
export default {
  components: { Title, BoxHead, DisplayCard },
  data() {
    return {
      data: funcData
    }
  },
  name: 'Function',
  props: {
    className: String
  }
}
</script>
