export function scrollToTop(position = 0) {
  // 使用requestAnimationFrame，如果没有则使用setTimeOut
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback) {
      return setTimeout(callback, 20)
    }
  }

  // 获取当前元素滚动的距离
  let scrollTopDistance =
    document.documentElement.scrollTop || document.body.scrollTop

  function smoothScroll() {
    // 如果你要滚到顶部，那么position传过来的就是0，下面这个distance肯定就是负值。
    let distance = position - scrollTopDistance
    // 每次滚动的距离要不一样，制造一个缓冲效果
    scrollTopDistance = scrollTopDistance + distance / 5
    // 判断条件
    if (Math.abs(distance) < 1) {
      window.scrollTo(0, position)
    } else {
      window.scrollTo(0, scrollTopDistance)
      requestAnimationFrame(smoothScroll)
    }
  }

  smoothScroll()
}
